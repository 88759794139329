<template>
	<div
		class="flex-column full-height position-relative bg-gray-light"
	>
		<div class=" bg-white ">
			<div
				class="justify-space-between items-center"
			>
				<button
					class="btn"
					:class="item_search.history_type == '' ? 'bg-identify' : 'bg-gray'"
					@click="item_search.history_type = ''; getSearch(1)"
				>전체</button>
				<button
					class="btn"
					:class="item_search.history_type == '1' ? 'bg-identify' : 'bg-gray'"
					@click="item_search.history_type = '1'; getSearch(1)"
				>진행중</button>
				<button
					class="btn"
					:class="item_search.history_type == '2' ? 'bg-blue' : 'bg-gray'"
					@click="item_search.history_type = '2'; getSearch(1)"
				>완료</button>
				<button
					class="btn"
					:class="item_search.history_type == '3' ? 'bg-red' : 'bg-gray'"
					@click="item_search.history_type = '3'; getSearch(1)"
				>실패</button>
			</div>

			<div
				class="justify-space-between items-center"
			>
				<div
					class="justify-space-between items-center flex-3"
				>
					<DatePicker :date="item_search.sDate" :is_move="1" @click="setDate('sDate', $event)" class="inline-block ml-10"></DatePicker>
					~
					<DatePicker :date="item_search.eDate" :is_move="1" @click="setDate('eDate', $event)" class="inline-block mr-10"></DatePicker>
				</div>

				<div class="justify-space-between items-center flex-2">
					<button
						class="btn btn-default"

						@click="reset"
					>초기화</button>
					<button
						class="btn btn-success"

						@click="getSearch(1)"
					>검색</button>
				</div>
			</div>
		</div>
		<div
			v-if="items_history.length > 0"
			class="mt-10 full-height overflow-y-auto "
		>
			<ul
			>
				<li
					v-for="(item, index) in history_list"
					:key="'item_' + index"
					class="justify-space-between-in-box bg-white mb-10 pa-10"
					@click="toDetail(item)"
				>
					<div>
						<div>{{ item.wDate }}</div>
						<div class="font-weight-600" :class="'color-' + item.o_status_color">{{ item.o_status_name }}</div>
					</div>
					<div class="mt-10">
						<div>상점 명</div>
						<div class="font-weight-bold">{{ item.shop_name }}</div>
					</div>
					<div class="mt-10">
						<div>주문자 명</div>
						<div class="font-weight-bold">{{ item.d_name }}</div>
					</div>
					<div class="mt-10">
						<div>결제 금액</div>
						<div class="font-weight-bold">{{ item.order_price | makeComma}}원</div>
					</div>
					<div class="mt-10">
						<div>완료 금액</div>
						<div class="font-weight-bold">{{ item.payment_amount | makeComma}}원</div>
					</div>
				</li>
			</ul>

			<Pagination
				:program="program"
				:align="'center'"
				:options="item_search"

				@click="getSearch"

				class="mt-auto pa-10"
			></Pagination>
		</div>
		<Empty
			v-else
			class="mt-10 mb-10"
		></Empty>
	</div>
</template>
<script>

import Empty from "../Layout/Empty";
import Pagination from "../../components/Pagination";
import DatePicker from "../../components/DatePicker";
export default {
	name: 'PaymentList'
	, components: {DatePicker, Pagination, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '결제목록'
				, title: true
				, bottom_payment: true
				, top: false
				, from: 'Index'
			}
			, items: []
			, item_search: {
				page: 1
				, list_cnt: 10
				, total_count: 0
				, cnt: 0
				, history_type: ''
				, sDate: this.$date.getSearchBaseDate('-')
				, eDate: this.$date.getToday('-')
			}
			, items_history: []
		}
	}
	, computed: {
		is_disabled: function(){
			let t = true

			return t
		}
		, history_list: function(){
			return this.items_history.filter(( item ) => {
				switch (item.o_status){
					case '1':
						item.o_status_name = '결제 진행중'
						item.o_status_color = ''
						break;
					case '2':
						item.o_status_name = '결제 완료'
						item.o_status_color = 'green'
						break;
					case '3':
						item.o_status_name = '결제 실패'
						item.o_status_color = 'red'
						break;
				}
				return item
			})
		}
	}
	, methods: {
		getPaymentList: async function(){
			try{
				this.$bus.$emit('on')
				let result = await this.$Axios({
					method: 'get'
					, url: '/payment/getPaymentList'
					, data: {
						page: this.item_search.page
						, list_cnt: this.item_search.list_cnt
						, history_type: this.item_search.history_type
						, sDate: this.item_search.sDate
						, eDate: this.item_search.eDate
					}
				})
				if(result.success){
					this.items_history = result.data.result
					this.item_search.total_count = result.data.tCnt
					this.item_search.cnt = result.data.cnt
				}else{
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('off')
			}
		}
		, getSearch: function(page = 1){
			if(page){
				this.item_search.page = page
			}
			this.getPaymentList()
		}
		, setDate: function(type, e){
			this.item_search[type] = e
			this.getPaymentList()
		}
		, reset: function(){
			this.item_search = {
				page: 1
				, list_cnt: 10
				, total_count: 0
				, cnt: 0
				, history_type: ''
				, sDate: this.$date.getToday('-')
				, eDate: this.$date.getToday('-')
			}
			this.getPaymentList()
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'PaymentDetail', params: { idx: item.uid }})
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getPaymentList()
	}
}
</script>